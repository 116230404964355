import axios from 'axios'

const redirectClio = async (documentId) => {
  try {
    const { data } = await axios.get(
      '/api/clio/files/' + documentId)
    window.open(data.redirectUrl, '_blank').focus()
  } catch (e) {

  }
}

export default redirectClio
