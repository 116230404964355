import React, { useRef, useEffect } from 'react'
import AceEditor from 'react-ace'
import './mode/knackly'
import 'brace/mode/javascript'
// import 'brace/theme/xcode'
import './theme/knackly'

import 'brace/ext/language_tools'
import 'brace/ext/searchbox'
import { useDrop } from 'react-dnd'
import { DND } from '../../config'

const Ace = ({
  name,
  mode,
  value,
  onChange,
  height,
  wrapEnabled,
  maxLines,
  className,
  placeholder,
  setOptions,
  otherProps,
  isExpression,
  onBlur,
  onFocus,
}) => {
  const editorRef = useRef(null)

  useEffect(() => {
    const editor = editorRef.current.editor
    const threshold = 50
    const scrollSpeed = 25
    let animationFrameId

    let parentElement = editor.container
    while (parentElement && !parentElement.classList.contains('col-9')) {
      parentElement = parentElement.parentElement
    }

    if (!parentElement) {
      return
    }

    const handleMouseMove = (e) => {
      const rect = parentElement.getBoundingClientRect()

      cancelAnimationFrame(animationFrameId)

      if (e.clientY - rect.top < threshold) {
        animateScroll(() => {
          parentElement.scrollBy(0, -scrollSpeed)
        })
      } else if (rect.bottom - e.clientY < threshold) {
        animateScroll(() => {
          parentElement.scrollBy(0, scrollSpeed)
        })
      }
    }

    const animateScroll = (callback) => {
      let start

      const step = (timestamp) => {
        if (!start) start = timestamp
        const progress = timestamp - start

        if (progress >= 10) {
          callback()
          start = timestamp
        } else {
          animationFrameId = requestAnimationFrame(step)
        }
      }
      animationFrameId = requestAnimationFrame(step)
    }

    editor.container.addEventListener('mousemove', handleMouseMove)

    return () => {
      cancelAnimationFrame(animationFrameId)
      editor.container.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  if (height) {
    if (typeof height !== 'string') {
      height = height.toString()
    }
    if (height.slice(-2) !== 'px') {
      height += 'px'
    }
  }
  const [, drop] = useDrop({
    accept: DND.TYPES.DNDITEMROOT,
    drop (item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      if (isExpression) {
        onChange(value + `${item.copyText}`)
      } else {
        onChange(value + ` {[${item.copyText}]}`)
      }

    },
  })

  return (
    <div ref={drop}>
      <AceEditor
        ref={editorRef}
        placeholder={placeholder}
        mode={mode || 'knackly'}
        theme="knackly"
        key={name}
        name={name}
        height={height}
        className={className}
        fontSize={'1rem'}
        width={'100%'}
        value={value || ''}
        onChange={(val) => {
          if (value !== val) {
            onChange(val)
          }
        }
        }
        debounceChangePeriod={400}

        onBlur={(event) => {
          if (onBlur) {
            onBlur(event)
          }
        }}

        onFocus={(event, editor) => {
          editor.commands.removeCommand(editor.commands.byName.indent)
          editor.commands.removeCommand(editor.commands.byName.outdent)
          editor.moveCursorTo(0, 0)
          if (onFocus) {
            onFocus(editor)
          }
        }}
        editorProps={{
          $blockScrolling: true,
        }}
        showPrintMargin={false}
        showGutter={false}
        wrapEnabled={wrapEnabled}
        highlightActiveLine={false}
        setOptions={setOptions} {...otherProps}
        maxLines={maxLines}
      />
    </div>
  )
}
Ace.defaultProps = {
  placeholder: '',
  name: 'AceEdit',
  wrapEnabled: true,
  cursorStart: -1,
  setOptions: {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: false,
    enableSnippets: false,
    showLineNumbers: true,
    tabSize: 0,
    tooltipFollowsMouse: true,
  },
  otherProps: {},
  height: 30,
  className: 'form-control rounded-0',

}

export default Ace
