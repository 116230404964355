import React from 'react'
import uuidv1 from 'uuid/v1'

const Settings = ({
  settings,
  app,
  appUrl,
  appOpt,
  groups,
  setNotification,
  setExternalAccess,
  setSendDocuments,
  setUploadDocuments,
}) => {
  const idSentDocuments = uuidv1()
  const idExternalAccess = uuidv1()

  const getOptions = () => {
    let options = []
    if (window.knacklyAddons.netdocuments) {
      options.push('NetDocuments')
    }
    if (window.knacklyAddons.onedrive) {
      options.push('OneDrive')
    }
    if (window.knacklyAddons.clio) {
      options.push('Clio')
    }
    return options
  }

  return (
    <div className={'col-5'}>
      <div className={'knackly-back p-3 mb-3'}>
        {/*Sent document*/}
        {(window.knacklyAddons.netdocuments || window.knacklyAddons.onedrive ||
            window.knacklyAddons.clio) &&
          (<div className="form-row align-items-center">
            <div className={'col-auto'}>
              <input type="checkbox"
                     id={idSentDocuments}
                     checked={Boolean(appOpt.isSendDocuments)}
                     onChange={() => {
                       const nextValue = !appOpt.isSendDocuments
                       setSendDocuments(
                         app.name,
                         nextValue)
                       if (nextValue === false) {
                         setUploadDocuments(app.name, '')
                       }
                     }}
                     style={{ verticalAlign: -1 }}
              />
              <label className="form-check-label mx-2"
                     htmlFor={idSentDocuments}>Send
                documents
                to:</label>
            </div>
            <div className="col-auto">
              <select className="form-control rounded-0"
                      value={appOpt.uploadDocuments || ''}
                      onChange={event => {
                        setUploadDocuments(app.name, event.target.value)
                        setSendDocuments(app.name, !!event.target.value)
                      }}
              >
                <option value={''}>Select a provider</option>
                {getOptions().map(option =>
                  (<option key={uuidv1()}
                           value={option}>{option}</option>),
                )}
              </select>
            </div>
          </div>)}
        {/*External access*/}
        {settings.isRegistration && app.extUsers &&
          (<div className="form-row align-items-center my-2">
            <div className={'col-auto'}>
              <input type="checkbox"
                     id={idExternalAccess}
                     checked={Boolean(appOpt.extAccess)}
                     style={{ verticalAlign: -1 }}
                     onChange={() => setExternalAccess(
                       app.name,
                       !appOpt.extAccess)}
              />
              <label className="form-check-label mx-2"
                     htmlFor={idExternalAccess}>External
                Access:</label>
            </div>
            {appUrl && (<div>
              <a href={appUrl}>{appUrl}</a>
            </div>)}
          </div>)}
        {/*Notification*/}
        {settings.isRegistration && appUrl &&
          (<div className="form-row align-items-center">
            <div className="col-auto">
              <p className={'my-0 mx-2'}
                 style={{ paddingLeft: 13 }}>Notification
                when completed:</p>
            </div>
            <div className="col-auto">
              <select className="form-control rounded-0"
                      value={appOpt.notification}
                      onChange={event => setNotification(
                        app.name, event.target.value)}
              >
                <option value={''}>Select a group</option>
                {groups.map(
                  group => <option key={app.name + group._id}
                                   value={group._id}>{group.name}</option>)}
              </select>
            </div>
          </div>)}
      </div>
    </div>
  )
}

export default Settings
