import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { Icon } from '../index'
import ModalConfirm from '../ModalConfirm'
import ModalCopy from './ModalCopy'
import ModalMove from './ModalMove'

import { canBeKey } from '../../config'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import {
  CurrentMemberSelector,
  CurrentTypeSelector, editTypeProperty,
  ElementsChangesAtom,
  getCurrentTab,
  getKind,
  getMemberKey, getTypeName,
} from '../../views/types/state'
import produce from 'immer'
import { useHistory } from 'react-router-dom'
import ModalReorder from './ModalReorder'

const ActionsList = () => {
  const history = useHistory()
  const memberKey = getMemberKey()
  const typeName = getTypeName()
  const kind = getKind(memberKey)
  const member = useRecoilValue(CurrentMemberSelector)
  const isExternal = useRecoilValue(editTypeProperty('isExternal'))
  const typeKey = useRecoilValue(editTypeProperty('key'))
  const onRemove = useRecoilCallback(({
    snapshot,
    set,
  }) => async () => {
    const elements = await snapshot.getPromise(ElementsChangesAtom)
    const type = await snapshot.getPromise(CurrentTypeSelector)

    set(ElementsChangesAtom, produce(elements, draft => {
      const hasElement = draft.find(element =>
        element._id === member._id
        && element.kind === member.kind
        && element.parent === type._id)
      if (hasElement) {
        return draft.filter(element =>
          element._id !== member._id
          && element.kind !== member.kind
          && element.parent !== type._id,
        )
      } else {
        draft.push({
          action: 'remove',
          _id: member._id,
          kind: kind,
          parent: type._id,
          parentKind: 'model',
        })
      }
    }))

    history.push(`/${window.tenancy}/types/${type.name}/${getCurrentTab()}`)
  }, [member])

  const setKey = useRecoilCallback(({
    snapshot,
    set,
  }) => async () => {
    const Type = await snapshot.getPromise(CurrentTypeSelector)
    const Member = await snapshot.getPromise(CurrentMemberSelector)
    if (Type.key !== Member.name) {
      set(editTypeProperty('key'), Member.name)
      set(editTypeProperty('keyType'), Member.type)
    } else {
      set(editTypeProperty('key'), '')
      set(editTypeProperty('keyType'), '')
    }
  }, [])

  if (!member) {
    return null
  }
  return (
    <Dropdown overlay={(
      <Menu>
        {(memberKey === 'properties' && canBeKey(member) && !isExternal) &&
          (<Menu.Item onClick={setKey}>
            <Icon type="icon-key1" style={{
              fontSize: '18px',
              verticalAlign: '0.1em',
            }}/><span>{typeKey === member.name
            ? 'Clear key'
            : 'Set key'}</span>
          </Menu.Item>)}
        {memberKey === 'apps' &&
          (<Menu.Item>
            <ModalReorder>
              <Icon type="icon-pencil-alt" style={{
                fontSize: '18px',
                verticalAlign: '0.1em',
              }}/><span> Reorder...</span>
            </ModalReorder>
          </Menu.Item>)}
        <Menu.Item>
          <ModalCopy title={`Copy ${kind} to other model`}
                     typeName={typeName}
                     memberName={member.name}
                     memberType={member.type}
                     memberKey={memberKey}>
             <span><Icon type="icon-copy" style={{
               fontSize: '18px',
               verticalAlign: '0.1em',
             }}/><span> Copy to other model</span></span>
          </ModalCopy>
        </Menu.Item>
        <Menu.Item>
          <ModalMove title={`Move ${kind} to other model`}
                     typeName={typeName}
                     memberName={member.name}
                     memberType={member.type}
                     memberKey={memberKey}>
             <span><Icon type="icon-arrows-alt" style={{
               fontSize: '18px',
               verticalAlign: '0.1em',
             }}/><span> Move to other model</span></span>
          </ModalMove>
        </Menu.Item>
        <Menu.Item>
          <ModalConfirm
            title={`Are you sure you want to delete ${kind} ${member.name}?`}
            submit={onRemove}>
              <span><Icon type="icon-trash" style={{
                fontSize: '18px',
                verticalAlign: '0.1em',
              }}/><span> Delete {kind}</span></span>
          </ModalConfirm>
        </Menu.Item>
      </Menu>)}
    >
      <Button title="Menu" shape="circle" className="circle-ant-btn"><Icon
        type="icon-bars"
        className="circle-ant-btn-icon"/>
      </Button>
    </Dropdown>
  )
}

export default ActionsList
