import React, { useState } from 'react'
import { Ace } from '../../../../components'
import axios from 'axios'
import moment from 'moment'
import classNames from 'classnames'
import Profile from '../integrations/profile'
import { ReactComponent as Spinner } from '../../../../images/icons/spinner.svg'
import {
  useRecoilCallback,
  useRecoilValue,
} from 'recoil'
import { CurrentTypeSelector, editMemberProperty, UserInfo } from '../../state'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'
import getWorkspaceURL from '../../../../config/workspaceURL'

const DocxTemplate = () => {
  const [inProgress, setProgress] = useState(false)
  const UserName = useRecoilValue(UserInfo)
  const filename = useRecoilValue(editMemberProperty('filename'))
  const filesize = useRecoilValue(editMemberProperty('filesize'))
  const date = useRecoilValue(editMemberProperty('date'))
  const name = useRecoilValue(editMemberProperty('name'))
  const templateId = useRecoilValue(editMemberProperty('_id'))

  const type = useRecoilValue(CurrentTypeSelector)

  const setFile = useRecoilCallback(({ set }) => ({
    filename,
    filesize,
    filepath,
    user,
    date,
  }) => {
    set(editMemberProperty('filename'), filename)
    set(editMemberProperty('filesize'), filesize)
    set(editMemberProperty('filepath'), filepath)
    if (user) {
      set(editMemberProperty('user'), user)
    }
    set(editMemberProperty('date'), date)
  }, [])

  const uploadFile = async file => {
    const data = new FormData()
    data.append('file', file)
    const config = {
      baseURL: getWorkspaceURL(),
    }
    const {
      data: {
        versionId,
        ext,
      },
    } = await axios.post('/upload/' + type._id, data, config)

    const compile = () => axios.post(
      `/upload/${type._id}/compile`, {
        templateId,
        versionId,
        ext,
      }, config)

    const response = await compile()

    const retry = () => new Promise((resolve => {
      setTimeout(async () => {
        try {
          const response_ = await compile()
          if (response_.status === 202) {
            return resolve(await retry())
          }
          resolve(response_)
        } catch (e) {
          setProgress(false)
        }
      }, 1000)
    }))

    if (response.status === 202) {
      return await retry()
    }

    return response
  }

  return (
    <div className={'col-12'}>
      <div className={'row'}>
        <div className={'col-6'}>
          <label>ASSEMBLED FILE NAME</label>
          <div
            className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3 TemplateWrapper'}>
            <EditMemberPropertyComponent path={'output'} defaultValue={''}>
              {({
                value,
                onChange,
              }) => (
                <Ace className={'border-0'} value={value || ''}
                     id={'templateText'}
                     placeholder={''}
                     onChange={onChange}
                     type={'text'} maxLines={Infinity}/>)}
            </EditMemberPropertyComponent>
          </div>
        </div>
        <div className={'col-6'}>
          <div className={'form-group m-0'}>
            <label htmlFor={'fileInfo'}>TEMPLATE FILE</label>
            <div>
              <label id={'fileInfo'} className={'labelForFile m-0 text-break'}>
                {filename || null}
              </label>
              <input id={'fileTemp'}
                     type={'file'}
                     accept=".docx"
                     className={'d-none'}
                     disabled={inProgress}
                     onChange={async e => {
                       setProgress(true)
                       const { data } = await uploadFile(e.target.files[0])

                       setFile({
                         filename: e.target.files[0].name,
                         filesize: e.target.files[0].size,
                         filepath: data.filepath,
                         user: data.user,
                         date: Date.now(),
                       })
                       e.target.value = null
                       setProgress(false)
                     }
                     }/>
            </div>
          </div>
        </div>

        <div className={'offset-6 col-6'}>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex justify-content-between'}>
              <label className={'my-0'}
                     style={{ paddingLeft: '0.75rem' }}>{moment(
                  date || Date.now()).format('L') + ' ' +
                moment(date || Date.now()).format('LT')}</label>
              {UserName && <label className={'my-0'}
                                  style={{ paddingRight: '0.75rem' }}>{UserName}</label>}
            </div>
            <label className={'my-0'}
                   style={{ paddingLeft: '0.75rem' }}>{filesize > 0
              ? ` ${filesize} bytes`
              : ''}</label>
          </div>
          <div className={'d-flex justify-content-end'}
               style={{ marginTop: 5 }}>
            {filename && (
              <a className={'btn dart-btn-outline  ml-2'} style={{ width: 120 }}
                 href={`${getWorkspaceURL()}/template/${type._id}/${name}`}
                 download>
                DOWNLOAD
              </a>)}
            <label htmlFor={'fileTemp'}
                   className={classNames('btn dart-btn-outline ml-2',
                     { 'disabled': inProgress })}
                   style={{
                     width: 120,
                     cursor: 'pointer',
                   }}>
              {inProgress ? <Spinner className={'rot'} style={{
                width: 30,
                top: -4,
                position: 'relative',
              }}/> : 'UPLOAD'}
            </label>
          </div>
        </div>
        {(window.knacklyAddons.netdocuments
            || window.knacklyAddons.onedrive
            || window.knacklyAddons.clio) &&
          <Profile typeName={type.name} templateName={name}/>}
      </div>
    </div>
  )
}

export default DocxTemplate
