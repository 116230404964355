import React from 'react'
import { MemberCard } from '../index'
import {
  getCurrentTab,
  getMemberName,
  SelectedMembersAtom,
} from '../../views/types/state'
import _ from 'lodash'
import { Icon } from '../index'
import { useRecoilValue } from 'recoil'

const getIcon = () => {
  const tab = getCurrentTab()
  if (tab === 'templates') {
    return Icon.fromTemplateType
  }
  if (tab === 'formulas') {
    return Icon.fromFormulaType
  }
  if (tab === 'layouts') {
    return Icon.fromLayoutType
  }
  if (tab === 'apps') {
    return Icon.fromAppType
  }
  return Icon.fromValueType
}

const MemberList = ({
  members,
  changeActive,
  typeKey = '',
}) => {

  const selectedMembers = useRecoilValue(SelectedMembersAtom)

  if (members[0] && members[0].position) {
    members = _.orderBy(members, [member => member.position], ['asc'])
  } else {
    members = _.orderBy(members, [member => member.name.toLowerCase()], ['asc'])
  }
  let nameMember = null
  if (members[0]) {
    nameMember = members[0].name
  }
  const activeMemberName = getMemberName()
  if (activeMemberName) {
    nameMember = activeMemberName
  }

  const getIconType = getIcon()

  return (<ul className="list-group w-100 rounded-0 mb-2 overflow-auto"
              style={{ maxHeight: 'calc(100vh - 19.8rem' }}>
    {members.map(member => (
      <MemberCard
        key={member._id}
        member={member}
        isActive={member._id in selectedMembers || nameMember ===
          member.name}
        changeActive={changeActive}
        getIconType={getIconType}
        typeKey={typeKey}
      />
    ))}
  </ul>)
}

export default MemberList
