import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Modal } from 'antd'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import {
  CurrentMembersSelector,
  CurrentTypeSelector,
} from '../../views/types/state'
import { Icon } from '../index'
import styled from 'styled-components'
import classNames from 'classnames'
import produce from 'immer'
import _ from 'lodash'
import axios from 'axios'
import { queryClient } from '../../config'

const StyledMemberCard = styled.li`
    background-color: ${props => props.theme.colorTable.plain} !important;
    border-color: ${props => props.theme.colorTable.plain} !important;
    color: ${props => props.theme.colorTable.text} !important;
`
const Block = styled.div`
    background-color: ${props => props.theme.colorTable.back}
    border-color: ${props => props.theme.colorTable.back}
`
const AppCard = 'AppCard'
const Card = ({
  member,
  move,
  index,
}) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: AppCard,
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover (item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) /
        2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      move(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { id: member._id, index, type: AppCard },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <StyledMemberCard
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={classNames(
        'list-group-item rounded py-1 px-3 my-1 cursorPointer prevent-select')}
    >
      <div className="d-flex">
        <div className={'mr-auto flex-grow-1 text-nowrap'} style={{
          maxWidth: '95%',
          textOverflow: 'ellipsis',
          overflow: 'unset',
        }}>
          <Icon
            type={Icon.fromAppType(member.type)}
            className={'align-middle '}
            style={{ marginTop: -3 }}/> {member.name.length > 22
          ? member.name.slice(0, 21) + '...'
          : member.name}
        </div>
      </div>
    </StyledMemberCard>)
}

const ModalReorder = ({
  children,
}) => {
  const [active, setActive] = useState(false)
  const Members = useRecoilValue(CurrentMembersSelector)
  const [members, setMembers] = useState([])

  useEffect(() => {
    if (Members[0] && Members[0].position) {
      setMembers(_.orderBy(Members, [member => member.position], ['asc']))
    } else {
      setMembers(
        _.orderBy(Members, [member => member.name.toLowerCase()], ['asc']))
    }
  }, [Members])

  const clearModal = () => {
    setActive(() => false)
  }

  const move = useCallback((dragIndex, hoverIndex) => {
    setMembers(produce((draft) => {
      const draggedCard = draft.splice(dragIndex, 1)[0]
      draft.splice(hoverIndex, 0, draggedCard)
    }))
  }, [])

  const onCancel = () => clearModal()
  const onActive = () => setActive(true)
  const onSubmit = useRecoilCallback(({
    snapshot,
    refresh,
  }) => async () => {

    const type = await snapshot.getPromise(CurrentTypeSelector)
    await axios.post(`/api/v2/types/model/${type._id}/apps`,
      { apps: members.map(member => member._id) })

    const { data } = await axios.get(`/api/v2/types/${type.kind}/${type._id}`)
    queryClient.setQueryData(`/api/v2/types/${type.kind}/${type._id}`, data)

    refresh(CurrentTypeSelector)
    onCancel()
  }, [members])

  return (<Fragment>
      <div onClick={onActive}>
        {children}
      </div>

      <Modal title={'Reorder apps'}
             visible={active}
             onCancel={onCancel}
             closable={false}
             width={410}
             footer={[
               <button key="submit"
                       className={'btn btn-secondary dart-btn'}
                       style={{ width: 125 }}
                       onClick={onSubmit}>
                 Save
               </button>,
               <button key="back"
                       className={'btn btn-outline-secondary dart-btn-outline'}
                       style={{ width: 125 }}
                       onClick={onCancel}>Cancel</button>,
             ]}
      >
        <Block className={'p-2 rounded'}>
          <ul className="list-group w-100 rounded-0 mb-2 overflow-auto"
              style={{ maxHeight: 'calc(100vh - 19.8rem' }}>
            {members.map((member, index) => (
              <Card
                key={member._id}
                index={index}
                move={move}
                member={member}
              />
            ))}
          </ul>
        </Block>
      </Modal>
    </Fragment>
  )
}

export default ModalReorder
